import React, { useState, useEffect } from "react";
import "./Login.css";
import { useForm } from "react-hook-form";
import Banner from "../../Components/Banner/Banner";
import ToggleButton from "../../Components/ToggleButton/ToggleButton";
import LoginImage from "../../Assets/Images/LoginImage.png";
import { LoginContent } from "../../Content/LoginPage.content";
import Email from "../../Components/Email/Email";
import Password from "../../Components/Password/Password";
import Button from "../../Components/Button/Button";
// import BackButton from "../../Components/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import SliderButton from "../../Assets/Images/SliderButtons.png";
import ForgotPassword from "../../Components/ForgotPassword/ForgotPasswordco";
import bannermobile from "../../Assets/Images/Banner-mobile.png";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import {
  CUSTOMER_LOGIN,
  EMPLOYEE_LOGIN,
} from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import socket from "../../sockets/socket";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { updateActiveUsers } from "../../redux/reducers/activeUsersSlice";
import BackButton from "./../../Components/BackButton/BackButton";
// const socket = io("https://apis-dev.heartyhelper.co/chat");

const Login = () => {
  const navigate = useNavigate();
  const handleClickSignup = () => {
    navigate("/register");
  };
  //toggle button functionality between customer and employee
  const [showCustomer, setShowCustomer] = useState(true);
  const [showEmployee, setShowEmployee] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [showBanner, setShowBanner] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();

  const handleForgotPasswordClick = () => {
    // setShowForgotPassword(true);
    const checkCustomer = showCustomer === true ? "customer" : "employee";
    navigate(`/forgot-password?user=${checkCustomer}`);
    // localStorage.setItem("showCustomer",JSON.stringify(showCustomer));
  };

  //mobile view
  const arrowButtonClick = () => {
    setShowBanner(!showBanner);
  };
  const connectToSocket = (email) => {
    if (!socket) {
      console.log("Socket instance does not exist.");
      return;
    }

    // You can directly emit events and set up event listeners.
    console.log("socket connected after login");
    socket.emit("new-user-add", email);
    console.log("Email stored adding as a user", email);

    // Handle a list of active users
    socket.on("get-users", (activeUsers) => {
      console.log("Active users: before", activeUsers);
      setActiveUsers(activeUsers);
      dispatch(updateActiveUsers(activeUsers));
      console.log("Active users: after", activeUsers);
    });

    socket.on("connect_error", (error) => {
      console.error("Socket.IO client after login connection error:", error);
    });
  };

  const onSubmit = (data) => {
    // console.log("Login form Submit", data);
    if (rememberMe) {
      localStorage.setItem("email", data.email);
      localStorage.setItem("password", data.password);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        emailId: data.email,
        password: data.password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}${showCustomer ? CUSTOMER_LOGIN : EMPLOYEE_LOGIN}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.token) {
            if (showCustomer) {
              navigate("/customer-post");
            } else {
              navigate("/employee-post");
            }
            // console.log("showCustomer", showCustomer);
            localStorage.setItem("token", result.token);
            toast.success("You have successfully logged in");
            connectToSocket(data.email);
          } else if (result.error === "Please Register to Login") {
            toast.error(result.error);
          } else if (result.error === "Password is Incorrect") {
            toast.error(result.error);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      toast.error("Please check the 'Remember Me' checkbox to log in.");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    if (storedEmail && storedPassword) {
      setValue("email", storedEmail);
      setValue("password", storedPassword);
      setRememberMe(true);
    }
    console.log("storedEmail", storedEmail);
  }, [setValue]);
  console.log("loginshowCustomer", showCustomer);
  console.log("storedEmail", localStorage.getItem("email"));
  console.log("loginshowEmployee", showEmployee);

  return (
    <>
      <div className="login-container">
        <div className="banner-login-desktop">
          <Banner
            image={LoginImage}
            headerContent={
              showCustomer
                ? LoginContent.LOGINIMAGEHEADER
                : showEmployee
                ? LoginContent.EMPLOYEECONTENT
                : ""
            }
            descriptionContent={LoginContent.LOGINIMAGEDESCRIPTION}
          />
        </div>
        <div className="login-form-desktop">
        <div className="backbutton-desktop">
              {/* <BackButton /> */}
              <BackButton  onClick={() => {
                      navigate(-1);
                    }} />
            </div>
          {showForgotPassword ? (
            <ForgotPassword
              setShowForgotPassword={setShowForgotPassword}
              showCustomer={showCustomer}
              showEmployee={showEmployee}
            />
          ) : (
            <>
                <div className="login-back-btn">
                  <BackButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
              <div className="tb-container">
                <ToggleButton
                  showCustomer={showCustomer}
                  setShowCustomer={setShowCustomer}
                  showEmployee={showEmployee}
                  setShowEmployee={setShowEmployee}
                />
              </div>
              <div className="form-login">
                <form
                  className="form-container"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="form-heading">{LoginContent.LOGINHEADER}</div>
                  <div className="form-description">
                    {LoginContent.LOGINDESCRIPTION}
                  </div>
                  <div className="email-container">
                    <Email
                      register={register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span className="error-msg">Please enter Email</span>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <span className="error-msg">Invalid Email Address</span>
                    )}
                  </div>
                  <div className="password-container">
                    <Password
                      register={register("password", {
                        required: true,
                        minLength: 6,
                        maxLength: 10,
                        pattern:
                          // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?!.*\s).*$/i,
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,10}$/,
                      })}
                    />
                    {errors.password && errors.password.type === "required" && (
                      <span className="error-msg">Please enter a password</span>
                    )}
                    {errors.password &&
                      errors.password.type === "minLength" && (
                        <span className="error-msg">
                          Password must be at least 8 characters long
                        </span>
                      )}
                    {errors.password &&
                      errors.password.type === "maxLength" && (
                        <span className="error-msg">
                          Password should be 10 characters only
                        </span>
                      )}
                    {errors.password && errors.password.type === "pattern" && (
                      <span className="error-msg">
                        Password must contain at least 1 uppercase letter, 1
                        lowercase letter, 1 number and 1 special character
                      </span>
                    )}
                  </div>
                  <div className="checkbox-container">
                    <div className="checkbox-input">
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                      />
                      <div className="password-content-left">
                        {LoginContent.PASSWORDCONTENTLEFT}
                      </div>
                    </div>
                  </div>
                  <Button buttonName={"Log in"} />
                </form>
                <div className="password-content-right">
                  <button
                    className="button-fp"
                    onClick={handleForgotPasswordClick}
                  >
                    {LoginContent.PASSWORDCONTENTRIGHT}
                  </button>
                </div>
              </div>
              <div className="footer-l">
                <p className="footer-content">
                  {LoginContent.LOGINFOOTER}{" "}
                  <span>
                    <button
                      className="signup-button"
                      onClick={handleClickSignup}
                    >
                      {" "}
                      Signup
                    </button>
                  </span>
                </p>
              </div>
            </>
          )}
        </div>

        {/* code for mobile */}
        {!showForgotPassword && (
          <div className="backbutton-mobile">{/* <BackButton /> */}</div>
        )}
        {/* {showBanner && (
          <div>
            <div className="banner-login-mobile">
              <Banner
                image={bannermobile}
                headerContent={LoginContent.LOGINIMAGEHEADERMOBILE}
                descriptionContent={LoginContent.LOGINIMAGEDESCRIPTION}
              />
            </div>
            <div className="sliderbutton-container-mobile">
              <img
                className="sliderbutton"
                src={SliderButton}
                onClick={arrowButtonClick}
                alt=""
              />
            </div>
          </div>
        )}

        {!showBanner && !showForgotPassword && (
          <div className="login-form-mobile">
            <div className="tb-container">
              <ToggleButton
                showCustomer={showCustomer}
                setShowCustomer={setShowCustomer}
                showEmployee={showEmployee}
                setShowEmployee={setShowEmployee}
              />
            </div>

            <div className="form-login">
              <form
                className="form-container"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-heading">{LoginContent.LOGINHEADER}</div>
                <div className="form-description">
                  {LoginContent.LOGINDESCRIPTION}
                </div>
                <div className="email-container">
                  <Email
                    register={register("email", {
                      required: true,
                    })}
                  />
                  {errors.email && (
                    <span className="error-msg">Please enter Email</span>
                  )}
                </div>
                <div className="password-container">
                  <Password
                    register={register("password", {
                      required: true,
                      minLength: 8,
                      pattern:
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?!.*\s).*$/i,
                    })}
                  />
                  {errors.password && errors.password.type === "required" && (
                    <span className="error-msg">Please enter a password</span>
                  )}
                  {errors.password && errors.password.type === "minLength" && (
                    <span className="error-msg">
                      Password must be at least 8 characters long
                    </span>
                  )}
                  {errors.password && errors.password.type === "pattern" && (
                    <span className="error-msg">
                      Password must contain at least 1 uppercase letter, 1
                      lowercase letter, 1 number and 1 special character
                    </span>
                  )}
                </div>
                <div className="checkbox-container">
                  <div className="checkbox-input">
                    <input type="checkbox" />
                    <div className="password-content-left">
                      {LoginContent.PASSWORDCONTENTLEFT}
                    </div>
                  </div>
                </div>
                <Button buttonName={"Log in"} />
              </form>
              <div className="password-content-right">
                <button
                  className="button-fp-mobile"
                  onClick={handleForgotPasswordClick}
                  // type="button"
                >
                  {LoginContent.PASSWORDCONTENTRIGHT}
                </button>
              </div>
            </div>

            <div className="footer-l">
              <p className="footer-content">
                {LoginContent.LOGINFOOTER}{" "}
                <span>
                  <button className="signup-button" onClick={handleClickSignup}>
                    {" "}
                    Signup
                  </button>
                </span>
              </p>
            </div>
          </div>
        )} */}

        {!showBanner && showForgotPassword && (
          <ForgotPassword
            setShowForgotPassword={setShowForgotPassword}
            showCustomer={showCustomer}
            showEmployee={showEmployee}
          />
        )}
      </div>
    </>
  );
};
export default Login;
