import React, { useState, useEffect } from "react";
import "./Register.css";
import Banner from "../../Components/Banner/Banner";
import customerregistration from "../../Assets/Images/customer-registration.png";
import employeeregistration from "../../Assets/Images/employee-registration.png";
import { RegistrationContent } from "../../Content/RegistrationPage.content";
import ToggleButton from "../../Components/ToggleButton/ToggleButton";
import RegistrationForm from "../../Components/RegistrationForm/RegistrationForm";
import BackButton from "../../Components/BackButton/BackButton";
import { useNavigate } from "react-router-dom";
import arrowbutton from "../../Assets/Images/arrow-button.png";
import bannermobile from "../../Assets/Images/Banner-mobile.png";

const Register = () => {
  const navigate = useNavigate();
  const handleClickLogin = () => {
    navigate("/login");
  };
  const onClickBackButton = () => {
    navigate("/");
  };
  //toggle button functionality between customer and employee
  const [showCustomer, setShowCustomer] = useState(true);
  const [showEmployee, setShowEmployee] = useState(false);
  const [showOtp, setShowOtp] = useState(false);

  //mobile view
  const [showBanner, setShowBanner] = useState(true);
  const arrowButtonClick = () => {
    setShowBanner(!showBanner);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="registration-container">
        <div className="banner-r-desktop">
          <Banner
            image={
              showCustomer
                ? customerregistration
                : showEmployee
                ? employeeregistration
                : ""
            }
            headerContent={
              showCustomer
                ? RegistrationContent.CUSTOMERREGISTRATIONIMAGEHEADER
                : showEmployee
                ? RegistrationContent.EMPLOYEEREGISTRATIONIMAGEHEADER
                : ""
            }
            descriptionContent={
              RegistrationContent.REGISTRATIONIMAGEDESCRIPTION
            }
          />
        </div>
        <div className="registration-form-desktop">
          {!showOtp && (
            <div className="backbutton-desktop">
              {/* <BackButton /> */}
              <BackButton  onClick={() => {
                      navigate(-1);
                    }} />
            </div>
          )}
          <div className="login-back-btn-r">
            <BackButton
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          {!showOtp && (
            <div className="tb-container-r">
              <ToggleButton
                showCustomer={showCustomer}
                setShowCustomer={setShowCustomer}
                showEmployee={showEmployee}
                setShowEmployee={setShowEmployee}
              />
            </div>
          )}
          <div className="form-registration">
            <RegistrationForm
              showCustomer={showCustomer}
              showEmployee={showEmployee}
              showOtp={showOtp}
              setShowOtp={setShowOtp}
            />
          </div>
          {!showOtp && (
            <div className="footer-r">
              <p className="footer-content">
                {RegistrationContent.REGISTRATIONFOOTER}{" "}
                <span>
                  <button className="login-button" onClick={handleClickLogin}>
                    {" "}
                    Login
                  </button>
                </span>
              </p>
            </div>
          )}
        </div>

        {/* mobile view */}
        {!showOtp && (
          <div className="backbutton-mobile">
            <BackButton />
          </div>
        )}
        {/* {showBanner && (
          <div>
            <div className="banner-r-mobile">
              <Banner
                image={bannermobile}
                headerContent={RegistrationContent.REGISTRATIONHEADERMOBILE}
                descriptionContent={
                  RegistrationContent.REGISTRATIONIMAGEDESCRIPTION
                }
              />
            </div>
            <div className="button-icon-mobile-container">
              <img
                className="button-icon-mobile"
                src={arrowbutton}
                onClick={arrowButtonClick}
                alt=""
              />
            </div>
          </div>
        )}
        {!showBanner && (
            <div className="registration-form-mobile">
              {!showOtp && (
                  <div className="tb-container-r">
                    <ToggleButton
                      showCustomer={showCustomer}
                      setShowCustomer={setShowCustomer}
                      showEmployee={showEmployee}
                      setShowEmployee={setShowEmployee}
                    />
                  </div>
              )}
              <div className="form-registration">
                <RegistrationForm
                  showCustomer={showCustomer}
                  showEmployee={showEmployee}
                  showOtp={showOtp}
                  setShowOtp={setShowOtp}
                />
              </div>
              {!showOtp && (
                <div className="footer-r">
                  <p className="footer-content">
                    {RegistrationContent.REGISTRATIONFOOTER}
                    <span>
                      <button
                        className="login-button"
                        onClick={handleClickLogin}
                      >
                        Login
                      </button>
                    </span>
                  </p>
                </div>
              )}
            </div>
        )} */}
      </div>
    </>
  );
};

export default Register;
